'use client'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { adminClient, useAdminToken } from '@repo/utils/fetcher/admin-fetcher'
import { setUserSelector, useAuthStore } from './use-auth'

const setTokenSelector = (s) => s.setToken

export function UserLoginConfig() {
  const setUser = useAuthStore(setUserSelector)
  const setToken = useAdminToken(setTokenSelector)
  const { data: session } = useSession()

  useEffect(() => {
    if (session && session.user && (session.user as any).token) {
      const token = (session.user as any).token

      adminClient.setHeader('authorization', `Bearer ${token}`)
      setToken(token)
      setUser({ ...session.user, authToken: token })
    }
  }, [session, setToken, setUser])

  return null
}
