'use client'
import { SessionProvider } from 'next-auth/react'
import { Toaster } from 'sonner'
import { SWRConfig } from 'swr'
import { swrConfig } from '@repo/utils/swr-config'
import { MAX_TIME_TOKEN_VERIFICATION } from '@/config/constants'
import { UserLoginConfig } from '@/services/auth/user-login-config.component'

export function ClientLayout({ children }: { children: React.ReactNode }) {
  return (
    <main className="h-full w-full overflow-hidden">
      <SessionProvider
        refetchInterval={MAX_TIME_TOKEN_VERIFICATION}
        refetchOnWindowFocus={true}>
        <UserLoginConfig />
        <SWRConfig value={swrConfig}>
          {children}
          <Toaster richColors position="top-center" />
        </SWRConfig>
      </SessionProvider>
    </main>
  )
}
